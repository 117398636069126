/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // refresh page if going "back"
  /*window.onpageshow = function(event) {
    if(event.persisted) {
      window.location.reload();
    }
  };*/

  // easy jquery reference variables
  var $document = $(document);
  var $html = $('html');
  var $body = $('body');

  // request animation frame
  var $flags = {
    scrolled: false,
    pauseScrolling: false,
    resized: false,
    resizedWidth: false,
    resizedHeight: false,
    pauseResizing: false,
  };
  var $timers = {
    scroll: -1,
    resize: -1,
    delta: 16.67 //  (1/60fps) / 1000ms = 16.67ms per frame
  };
  var $window = {
    width: window.innerWidth,
    lastWidth: window.innerWidth,
    height: window.innerHeight,
    lastHeight: window.innerHeight,
    scrollX: window.pageXOffset,
    lastScrollX: 0,
    scrollY: window.pageYOffset,
    lastScrollY: 0
  };

  /* prepare scroll function */
  window.addEventListener('scroll', function() {
    $flags.scrolled = true;
    $window.scrollX = window.pageXOffset;
    $window.scrollY = window.pageYOffset;

    if($window.scrollY < $window.lastScrollY) {
      $html.attr('data-direction', 'up');
    } else {
      $html.attr('data-direction', 'down');
    }
    $window.lastScrollY = $window.scrollY;

    if($timers.scroll !== -1) {
      clearTimeout($timers.scroll);
    }

    $timers.scroll = window.setTimeout(function() {
      $flags.scrolled = false;
    }, $timers.delta);
  });

  /* prepare resize function */
  window.addEventListener('resize', function() {
    $flags.resized = true;
    $window.width = window.innerWidth;
    $window.height = window.innerHeight;

    if($window.width !== $window.lastWidth) {
      $flags.resizedWidth = true;
    }
    $window.lastWidth = $window.width;

    if($window.height !== $window.lastHeight) {
      $flags.resizedHeight = true;
    }
    $window.lastHeight = $window.height;

    if($timers.resize !== -1) {
      clearTimeout($timers.resize);
    }

    $timers.resize = window.setTimeout(function() {
      $flags.resized = false;
      $flags.resizedWidth = false;
      $flags.resizedHeight = false;
    }, $timers.delta);
  });

  var requestScrollAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.scrolled) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeAnimation = function(callback, delay) {
    delay = delay || $timers.delta;
    
    return setInterval(function() {
      if($flags.resized) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeWidthAnimation = function(callback, delay) {
    delay = delay || $timers.delta;
    
    return setInterval(function() {
      if($flags.resizedWidth) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeHeightAnimation = function(callback, delay) {
    delay = delay || $timers.delta;
    
    return setInterval(function() {
      if($flags.resizedHeight) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  // match media variables
  var $mm = gsap.matchMedia();

  var $mqXL = '(min-width: 1281px)';
  var $isXL = window.matchMedia($mqXL);

  var $mqLG = '(min-width: 1025px)';
  var $isLG = window.matchMedia($mqLG);

  var $mqMD = '(min-width: 769px)';
  var $isMD = window.matchMedia($mqMD);

  var $mqSM = '(min-width: 641px)';
  var $isSM = window.matchMedia($mqSM);

  var $mqHorizontal = '(min-height: 881px) and (min-width: 1025px)';
  var $isHorizontal = window.matchMedia($mqHorizontal);

  var $mqVertical = '(max-height: 880px), (max-width: 1024px)';
  var $isVertical = window.matchMedia($mqHorizontal);

  // PAS helper
  var $isPAS = $body.hasClass('page-template-pas');

  // browser detection
  var $ua = navigator.userAgent;

  if($ua.indexOf('Safari') !== -1 && $ua.indexOf('Chrome') === -1) {
    $html.addClass('safari');
  }

  if($ua.indexOf('Chrome') !== -1) {
    $html.addClass('chrome');
  }

  if($ua.match(/iP(hone|od|ad)/i) ) {
    $html.addClass('browser-ios');
  }

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test($ua)) {
    $html.addClass('browser-mobile');
  }

  // gsap smooth scrolling
  var $normalizeScroll = true;
  if($html.hasClass('safari')) {
    $normalizeScroll = false;
  }

  var $smoother = ScrollSmoother.create({
    smooth: 1,
    effects: true,
    normalizeScroll: $normalizeScroll
  });

  // set js cookie
  function setCookie(name, value, days) {
    var expires = '';

    if(days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = '; expires='+date.toGMTString();
    }

    document.cookie = name+'='+value+expires+'; path=/';
  }
  
  // get js cookie
  function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for(var i=0; i < ca.length; i++) {
      var c = ca[i];

      while(c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if(c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }

    return false;
  }

  // convert viewport units to pixels
  function toPX(value) {
    return parseFloat(value) / 100 * (/vh/gi.test(value) ? window.innerHeight : window.innerWidth);
  }

  // check sticky header
  function checkStickyHeader() {
    if(!$flags.pauseScrolling) {
      // toggle "is-top" class
      if($window.scrollY < 56) {
        $html.addClass('is-top');
      } else {
        $html.removeClass('is-top');
      }
    }
  }
  requestScrollAnimation(checkStickyHeader);

  // check fixed dropdown
  function checkFixedDropdown() {
    if($html.hasClass('state--dropdown-open') && !$html.hasClass('state--dropdown-fixed')) {
      var $dropdownComponent = $('.dropdown-component.active');
      var $dropdownOffset = $dropdownComponent.offset().top;
      var $dropdownOverlayOffset = $('.dropdown-component_overlay').offset().top;

      if($window.scrollY >= $dropdownOverlayOffset) {
        $smoother.scrollTo($dropdownOverlayOffset);
        $smoother.paused(true);

        //$html.addClass('state--dropdown-fixed');
        //$html.attr('data-direction', 'down');
      }
    }
  }
  //requestScrollAnimation(checkFixedDropdown);

  // adjust dropdown overlay position
  function adjustDropdown() {
    var $dropdownComponent = $('.dropdown-component.active');
    if($dropdownComponent.length) {
      $('.dropdown-component_overlay').css({ top: $dropdownComponent.offset().top });
    }
  }
  requestResizeAnimation(adjustDropdown);

  // close dropdown
  function closeDropdown() {
    $('.dropdown-component.active').removeClass('active');
    $html.removeClass('state--dropdown-open');
    //$html.removeClass('state--dropdown-fixed');

    if(!$html.hasClass('state--gallery-expanded')) {
      $smoother.paused(false);
    }
  }

  // resize accordions
  function accordionResize() {
    $('.accordion-component.state--open').each(function() {
      var $accordion = $(this);
      var $content = $accordion.find('.accordion-component_content');
      var $contentHeight = $accordion.find('.text-component').outerHeight();

      gsap.set($content, { height:$contentHeight });
    });
  }
  requestResizeAnimation(accordionResize);

  // cursor movement
  var $cursorComponent  = $('.cursor-component');
  function moveCursor(e) {
    var $x = e.clientX;
    var $y = e.clientY;

    gsap.set($cursorComponent, { x: $x, y: $y });
  }

  // open menu
  function openMenu() {
    $html.addClass('state--menu-open');

    var $menuTimeline = new TimelineMax()
    .fromTo('.menu-wrapper', {
      y: '-100%'
    }, {
      duration: 0.5,
      y: '0%'
    }, 0)
    .fromTo('.menu-wrapper_reverse', {
      y: '100%'
    }, {
      duration: 0.5,
      y: '0%'
    }, 0);

    if($isLG.matches) {
      $menuTimeline.fromTo('.menu > .menu-item', {
        x: '60%',
        opacity: 0
      }, {
        duration: 0.4,
        x: '50%',
        opacity: 1,
        stagger: 0.1
      }, 0);
    } else {
      $menuTimeline.fromTo('.menu > .menu-item', {
        x: '10%',
        opacity: 0
      }, {
        duration: 0.4,
        x: '0%',
        opacity: 1,
        stagger: 0.1
      }, 0);
    }
  }

  // menu adjustments
  function menuAdjustments() {
    if($html.hasClass('state--menu-open') && !$html.hasClass('state--sub-menu-open')) {
      var $menuTimeline = new TimelineMax();

      if($isLG.matches) {
        $menuTimeline.to('.menu > .menu-item', {
          duration: 0.4,
          x: '50%',
          opacity: 1,
          stagger: 0.1
        }, 0);
      } else {
        $menuTimeline.to('.menu > .menu-item', {
          duration: 0.4,
          x: '0%',
          opacity: 1,
          stagger: 0.1
        }, 0);
      }
    }
  }

  if($isLG.addEventListener) {
    $isLG.addEventListener('change', menuAdjustments);
  } else {
    $isLG.addListener(menuAdjustments);
  }

  // reset menu (after menu is closed)
  function resetMenu() {
    $html.removeClass('state--menu-open');
    $html.removeClass('state--sub-menu-open');
    $('.menu-item--open').removeClass('menu-item--open');
    gsap.set('.sub-menu li', { opacity: 0 });
  }

  // close menu
  function closeMenu() {
    var $menuTimeline = new TimelineMax()
    .fromTo('.menu-wrapper', {
      y: '0%'
    }, {
      duration: 0.5,
      y: '-100%'
    }, 0)
    .fromTo('.menu-wrapper_reverse', {
      y: '0%'
    }, {
      duration: 0.5,
      y: '100%',
      onComplete: resetMenu
    }, 0);
  }

  // open sub menu
  function openSubMenu($menuItem) {
    $menuItem.addClass('menu-item--open');
    var $subMenuItems = $menuItem.find('.sub-menu > li');

    var $subMenuTimeline = new TimelineMax();

    var $submenuItemsStart = 0;

    if(!$html.hasClass('state--sub-menu-open') && $isLG.matches) {
      $menuItems = $('.menu > .menu-item');
      $menuItemIndex = $menuItems.index($menuItem);

      $menuItems.each(function(index) {
        var $curMenuItem = $(this);
        var $diff = Math.abs(index - $menuItemIndex);

        $subMenuTimeline.fromTo($curMenuItem, {
          x: '50%'
        }, {
          delay: ($diff * 0.1),
          duration: 0.8,
          x: '0%'
        }, 0);
      });

      $submenuItemsStart = 0.8;
    }

    $html.addClass('state--sub-menu-open');

    $subMenuTimeline.fromTo($subMenuItems, {
      x: '10%',
      opacity: 0
    }, {
      duration: 0.4,
      x: '0%',
      opacity: 1,
      stagger: 0.1
    }, $submenuItemsStart);
  }

  // close sub menu
  function closeSubMenu($menuItem) {
    $menuItem.removeClass('menu-item--open');
  }

  // text animations
  function setupTextAnimations() {
    var $textLinesArray = [];

    // text animations
    function textSplit() {
      $('.text-animation--tracking').each(function() {
        var $text = $(this);
        var $textLines = new SplitText($text, {
          type: 'lines',
          linesClass: 'line'
        });

        $textLinesArray.push($textLines);
      });
    }
    textSplit();

    // reset text split
    function resetTextSplit() {
      if($textLinesArray.length > 0) {
        for(var i = 0; i < $textLinesArray.length; i++) {
          $textLines = $textLinesArray[i];
          $textLines.revert();
        }

        $textLinesArray = [];
        textSplit();
      }
    }
    requestResizeAnimation(resetTextSplit);

    function textAnimations() {
      $('.text-animation').each(function() {
        var $text = $(this);

        if($isPAS && $isHorizontal.matches) {
          ScrollTrigger.create({
            trigger: $text,
            horizontal: true,
            start: 'left 75%',
            toggleClass: 'in-view',
            once: true,
            scroller: '.side-scroller_wrapper'
          });
        } else {
          ScrollTrigger.create({
            trigger: $text,
            start: 'top 75%',
            toggleClass: 'in-view',
            once: true
          });
        }
      });
    }
    textAnimations();
    $isHorizontal.addListener(textAnimations);

    $('.text-animation').each(function() {
      var $text = $(this);

      if($isPAS) {
        $mm.add($mqHorizontal, function() {
          ScrollTrigger.create({
            trigger: $text,
            horizontal: true,
            start: 'left 75%',
            toggleClass: 'in-view',
            once: true,
            scroller: '.side-scroller_wrapper'
          });
        });

        $mm.add($mqVertical, function() {
          ScrollTrigger.create({
            trigger: $text,
            start: 'top 75%',
            toggleClass: 'in-view',
            once: true
          });
        });
      } else {
        ScrollTrigger.create({
          trigger: $text,
          start: 'top 75%',
          toggleClass: 'in-view',
          once: true
        });
      }
    });
  }

  // image component animations
  function setupImageComponentAnimations() {
    $('.image-component').each(function() {
      var $component = $(this);

      ScrollTrigger.create({
        trigger: $component,
        start: 'bottom 100%',
        toggleClass: 'in-view',
        once: true
      });
    });
  }

  // reveal button animations
  function setupRevealButtonAnimations() {
    $('.reveal-buttons').each(function() {
      var $buttons = $(this);

      ScrollTrigger.create({
        trigger: $buttons,
        start: 'bottom 100%',
        toggleClass: 'in-view',
        once: true
      });
    });
  }

  // split scroll sections
  function setupSplitScrollSections() {
    $mm.add($mqLG, function() {
      $('.split-scroll').each(function() {
        var $splitScroll = $(this);
        var $splitScrollSticky = $splitScroll.find('.split-scroll_sticky');

        var $start = 'top 0%';
        if($splitScroll.hasClass('news-archive')) {
          $start = 'top 360px';
        }

        var $splitScrollTrigger = ScrollTrigger.create({
          trigger: $splitScrollSticky,
          start: $start,
          endTrigger: $splitScroll,
          end: 'bottom 100%',
          pin: true
        });

        setTimeout(function() {
          $splitScrollTrigger.refresh();
        }, 1000);
      });
    });
  }

  // two column content animations
  function setupTwoColumnAnimations() {
    $('.page-content_row').each(function() {
      var $contentWrapper = $(this);
      var $contentTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: $contentWrapper,
          start: 'top 100%',
          end: 'top 50%',
          scrub: 1
        }
      });

      var $contentItems = $contentWrapper.find('.page-content_copy');
      $contentTimeline.fromTo($contentItems, {
        x: toPX('15vw')
      }, {
        duration: 1,
        stagger: 0.1,
        x: '0%'
      }, 0);
    });
  }

  // card animations
  function setupCardAnimations() {
    // image load
    $('.card-component').each(function() {
      var $card = $(this);

      if($isPAS) {
        $mm.add($mqHorizontal, function() {
          ScrollTrigger.create({
            trigger: $card,
            horizontal: true,
            start: 'left 75%',
            toggleClass: 'in-view',
            once: true,
            scroller: '.side-scroller_wrapper'
          });
        });

        $mm.add($mqVertical, function() {
          ScrollTrigger.create({
            trigger: $card,
            start: 'top 75%',
            toggleClass: 'in-view',
            once: true
          });
        });
      } else {
        ScrollTrigger.create({
          trigger: $card,
          start: 'top 75%',
          toggleClass: 'in-view',
          once: true
        });
      }
    });
  }

  // set up float labels
  function setupFormRow($formRow) {
    var $input_wrapper = $formRow;
    var $field = $input_wrapper.find('input,textarea');
    var $label = $input_wrapper.find('label:not(.checkbox)');
    var $placeholder = $field.attr('placeholder');
    var $hasPlaceholder = false;
    if(typeof $placeholder !== 'undefined' && $placeholder !== '') {
      $hasPlaceholder = true;
    }

    if($field.val() !== '' || $hasPlaceholder) {
      $label.addClass('shrink');
    }

    $field.focus(function() {
      $label.addClass('shrink');
    }).blur(function() {
      if($field.val() === '' && !$hasPlaceholder) {
        $label.removeClass('shrink');
      }
    });
  }

  // display current vote
  function displayVote(artwork) {
    // toggle buttons
    $('.btn--voted').removeClass('btn--voted');
    $('.btn[data-artwork-id="'+artwork.ID+'"]').addClass('btn--voted');

    // show current vote box
    if($('.exhibition-vote').length > 0) {
      $('.exhibition-vote_image img').attr('src', artwork.thumbnail);
      $('.exhibition-vote_selection').attr('href', artwork.url);
      $('.exhibition-vote_selection h3').html(artwork.title+'<br /><span>'+artwork.artist_name+'</span>');

      $('.exhibition-vote').slideDown();
    }
  }

  // get current vote
  function getCurrentVote() {
    var $email = getCookie('email');
    if($email) {
      $('.vote-slider_form input[name="email"]').attr('value', $email);

      var $form = $('.vote-slider_form form').first();
      var $nonce = $form.find('input[name="nonce"]').val();
      var $exhibition_id = $form.find('input[name="exhibition_id"]').val();
      var $formData = {
        'nonce': $nonce,
        'action': 'get_artwork_vote',
        'email': $email,
        'exhibition_id': $exhibition_id
      };

      $.post($form.attr('action'), $formData, function(response) {
        if(response) {
          displayVote(response);
        } else {
          $('.exhibition-vote').hide();
        }
      }, 'json');
    } else {
      $('.exhibition-vote').hide();
    }
  }

  // check if current artwork gallery slide contains a video
  function checkArtworkVideo() {
    var $videoIframe = $('.artwork-gallery .swiper-slide-active iframe');

    if($videoIframe.length > 0) {
      var $video = $videoIframe.data('video');
      var $vimeoMatch = $video.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/);
      var $youtubeMatch = $video.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);

      if($vimeoMatch) {
        $videoIframe.attr('src', 'https://player.vimeo.com/video/'+$vimeoMatch[2]+'?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&muted=1');
      } else if($youtubeMatch) {
        $videoIframe.attr('src', 'https://www.youtube.com/embed/'+$youtubeMatch[7]+'?autoplay=1');
      }
    } else {
      $('.swiper-slide iframe').attr('src', '');
    }
  }

  // initialize artwork gallery
  function setupArtworkGallery() {
    // gallery swiper
    var $gallerySwiper = new Swiper('.artwork-gallery .swiper', {
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.artwork-gallery .swiper-pagination',
        clickable: true,
        renderBullet: function(index, className) {
          var $thumbnail = $('.artwork-gallery .swiper-slide:eq('+(index)+')').attr('data-thumbnail');

          return '<span class="'+className+'"><img src="'+$thumbnail+'" alt="" /></span>';
        }
      },
      on: {
        init: checkArtworkVideo,
        transitionEnd: checkArtworkVideo
      }
    });

    // gallery: open expanded view
    $document.on('click', '.artwork-gallery .swiper-slide img', function(e) {
      e.preventDefault();

      // set gallery image
      var $image = $(this);
      var $imageContainer = $image.parent().parent();

      $('.gallery-overlay_video').hide();
      $('.gallery-overlay_image').removeClass('loaded').show();

      $('.gallery-overlay_image img').attr('src', $imageContainer.attr('data-overlay'));
      $('.gallery-overlay_zoom img').attr('src', $imageContainer.attr('data-zoom'));

      $('.gallery-overlay').imagesLoaded(function() {
        $('.gallery-overlay_image').addClass('loaded');
      });
      
      $('.cursor').hide();
      $cursorComponent.removeClass('state--visible');

      $html.addClass('state--gallery-expanded');
      $smoother.paused(true);
    });

    // gallery: close expanded view
    $document.on('click', '.gallery-overlay_close', function(e) {
      e.preventDefault();

      $html.removeClass('state--gallery-expanded');
      $html.removeClass('state--gallery-zoom');
      $smoother.paused(false);
    });

    // gallery zoom in
    $document.on('click', '.gallery-overlay_image img', function(e) {
      e.preventDefault();

      $('.cursor').hide();
      $cursorComponent.removeClass('state--visible');

      if($isLG.matches) {
        $html.addClass('state--gallery-zoom');
      } else {
        $html.removeClass('state--gallery-expanded');
        $html.removeClass('state--gallery-zoom');
        $smoother.paused(false);
      }
    });

    // gallery zoom out
    $document.on('click', '.gallery-overlay_zoom img', function(e) {
      e.preventDefault();
      
      $('.cursor').hide();
      $cursorComponent.removeClass('state--visible');

      $html.removeClass('state--gallery-zoom');
    });
  }

  // setup page hero
  function setupPageHero() {
    var $heroHeading = false;

    // split and adjust heading
    function pageHero() {
      $heroHeading = new SplitText('.page-hero h1', {
        type: 'lines words',
        linesClass: 'line',
        wordsClass: 'word'
      });

      $('.page-hero h1 .line').each(function() {
        var $line = $(this);

        $line.wrapInner('<span></span>');
        var $span = $line.find('span');
        var $spanWidth = $span.width();
        var $paddingLeft = 0;
        var $maxPaddingLeft = Math.floor(($('.page-hero h1').width() - $spanWidth) / 2);

        if($maxPaddingLeft > 160) {
          $maxPaddingLeft = 160;
        }

        $paddingLeft = Math.random() * $maxPaddingLeft;

        $span.css({ paddingLeft: $paddingLeft });
      });

      setupMatter();
    }
    pageHero();

    // reset page hero
    function resetPageHero() {
      if($html.hasClass('browser-ios') || ($html.hasClass('browser-mobile') && $html.hasClass('chrome'))) {
        if($flags.resizedWidth) {
          if($heroHeading) {
            $heroHeading.revert();

            pageHero();
          }
        }
      } else {
        if($heroHeading) {
          $heroHeading.revert();

          pageHero();
        }
      }

    }
    requestResizeAnimation(resetPageHero); // FIXME
  }

  // home exhibition callout
  function setupHomeExhibitionCalloutAnimation() {
    var $exhibitionTimeline;

    $mm.add($mqLG, function() {
      $exhibitionTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.home-exhibition_wrapper',
          start: 'top 0%',
          endTrigger: '.home-exhibition',
          end: 'bottom 100%',
          pin: true,
          anticipatePin: 1,
          scrub: 1,
          snap: {
            snapTo: 0.25,
            delay: 0,
            duration: { min: 0.2, max: 3 }
          }
        }
      });

      $exhibitionTimeline.addLabel('step-1')
        .fromTo('.home-exhibition_image', {
          'clip-path': 'polygon(38.1714285714% 27.2072072072%, 49.6% 26.1261261261%, 61.8285714286% 54.5945945946%, 52.1142857143% 63.7837837838%, 52.1142857143% 74.0540540541%, 43.2% 62.3423423423%)'
        }, {
          duration: 1,
          'clip-path': 'polygon(26.1714285714% 14.5945945946%, 50.8571428571% 14.5945945946%, 76.9142857143% 66.3063063063%, 50.0571428571% 85.4054054054%, 23.0857142857% 41.981981982%, 30.1714285714% 25.5855855856%)'
        })
        .addLabel('step-2')
        .fromTo('.home-exhibition_image', {
          'clip-path': 'polygon(26.1714285714% 14.5945945946%, 50.8571428571% 14.5945945946%, 76.9142857143% 66.3063063063%, 50.0571428571% 85.4054054054%, 23.0857142857% 41.981981982%, 30.1714285714% 25.5855855856%)'
        }, {
          delay: 0.5,
          duration: 1,
          'clip-path': 'polygon(49.7142857143% 9.3693693694%, 76% 18.5585585586%, 100% 7.7477477477%, 96.8% 62.3423423423%, 61.4857142857% 90.6306306306%, 0% 84.6846846847%)'
        })
        .addLabel('step-3')
        .fromTo('.home-exhibition_image', {
          'clip-path': 'polygon(49.7142857143% 9.3693693694%, 76% 18.5585585586%, 100% 7.7477477477%, 96.8% 62.3423423423%, 61.4857142857% 90.6306306306%, 0% 84.6846846847%)'
        }, {
          delay: 0.5,
          duration: 1,
          'clip-path': 'polygon(2.9714285714% 7.2072072072%, 50.9714285714% 0%, 94.0571428571% 27.2072072072%, 97.0285714286% 93.3333333333%, 10.6285714286% 100%, 2.9714285714% 36.3963963964%)'
        })
        .addLabel('step-4')
        .fromTo('.home-exhibition_image', {
          'clip-path': 'polygon(2.9714285714% 7.2072072072%, 50.9714285714% 0%, 94.0571428571% 27.2072072072%, 97.0285714286% 93.3333333333%, 10.6285714286% 100%, 2.9714285714% 36.3963963964%)'
        }, {
          delay: 0.5,
          duration: 1,
          'clip-path': 'polygon(0% 0%, 50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%)'
        }
      );

      $exhibitionTimeline.fromTo('.home-exhibition_image picture:nth-child(2) img', {
        opacity: 0
      }, {
        delay: 0.5,
        duration: 0.5,
        opacity: 1
      }, 'step-1');

      $exhibitionTimeline.fromTo('.home-exhibition_image picture:nth-child(3) img', {
        opacity: 0
      }, {
        delay: 1,
        duration: 0.5,
        opacity: 1
      }, 'step-2');

      $exhibitionTimeline.fromTo('.home-exhibition_image picture:nth-child(4) img', {
        opacity: 0
      }, {
        delay: 1,
        duration: 0.5,
        opacity: 1
      }, 'step-3');

      $exhibitionTimeline.fromTo('.home-exhibition_image picture:nth-child(5) img', {
        opacity: 0
      }, {
        delay: 1,
        duration: 0.5,
        opacity: 1
      }, 'step-4');

      //if($isLG.matches) {
        $exhibitionTimeline.fromTo('.home-exhibition_cta', {
          y: 0
        }, {
          duration: 5.5,
          y: -80
        }, 0);
      //}
    });
  }

  // home testimonials
  function setupHomeTestimonials() {
    var $testimonialsSwiper = new Swiper('.home-testimonials .swiper', {
      loop: true,
      autoplay: {
        delay: 8000,
      },
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  // home PAS animation
  function setupHomePASAnimation() {
    var $pasTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.home-pas',
        start: 'top 50%'
      }
    });

    // step 1
    $pasTimeline.to('.home-pas_bg--1', {
      duration: 0.8,
      width: '100%'
    }, 0);

    // step 2
    $pasTimeline.to('.home-pas_bg--1', {
      duration: 0.6,
      width: '6.25%'
    }, 0.8);

    $pasTimeline.to('.home-pas_bg--2', {
      duration: 0.6,
      width: '93.75%'
    }, 0.8);

    // step 3
    $pasTimeline.to('.home-pas_bg--2', {
      duration: 0.4,
      width: '18.75%'
    }, 1.4);

    $pasTimeline.to('.home-pas_bg--3', {
      duration: 0.4,
      width: '75%'
    }, 1.4);

    // step 4
    $pasTimeline.to('.home-pas_bg--3', {
      duration: 0.2,
      width: '25%'
    }, 1.8);

    $pasTimeline.to('.home-pas_bg--4', {
      duration: 0.2,
      width: '50%'
    }, 1.8);
  }

  // prize categories animation
  function setupPrizeCategoriesAnimation() {
    var $prizeTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.the-prize-categories',
        start: 'top 100%',
        end: 'bottom 75%',
        scrub: 1
      }
    });

    var $prizeItems = $('.prize-category, .prize-divider');
    $prizeTimeline.fromTo($prizeItems, {
      x: toPX('25vw')
    }, {
      duration: 1,
      stagger: 0.1,
      x: '0%'
    }, 0);
  }

  // prize finalists animation
  function setupPrizeFinalistsAnimation() {
    var $finalistsTimeline = false;

    function prizeFinalistsAnimation() {
      var $distance = $('.the-prize-finalists').outerHeight();

      $finalistsTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.the-prize-finalists',
          start: 'top 200%',
          end: 'bottom 0%',
          scrub: 1
        }
      });

      $('.the-prize-finalists_images div').each(function(index) {
        var $img = $(this);
        var $speed = Math.random();
        var $y = (1 - parseFloat($speed)) * $distance;

        $finalistsTimeline.to($img, {
          ease: 'Linear.easeNone',
          y: -$y
        }, 0);
      });
    }
    prizeFinalistsAnimation();

    // reset prize finalists timeline
    function resetPrizeFinalistsAnimation() {
      if($finalistsTimeline) {
        $finalistsTimeline.revert();
        $finalistsTimeline.kill();
        prizeFinalistsAnimation();
      }
    }
    requestResizeWidthAnimation(resetPrizeFinalistsAnimation);
  }

  // jury process animation
  function setupJuryProcessAnimation() {
    // jury process
    var $juryTimeline =  gsap.timeline({
      scrollTrigger: {
        trigger: '.the-jury-process_considerations',
        start: 'top 100%-=224px',
        end: 'bottom 100%-=64px',
        scrub: 1
      }
    });

    $('.the-jury-process_consideration').each(function(index) {
      var $consideration = $(this);
      var $yFrom = (index * -100)+'%';
      var $duration = (index * 1);

      $juryTimeline.fromTo($consideration, {
        y: $yFrom
      }, {
        duration: $duration,
        ease: 'Linear.easeNone',
        y: '0%'
      }, 0);
    });
  }

  // previous jurors filters
  function filterJurors(e) {
    e.preventDefault();

    var $button = $(this);
    var $filter = $button.attr('data-filter');

    $('.dropdown-component_button-text').html($button.text());

    var $jury = $('.jurors-previous .grid--people > li');
    $jury.show();

    if($filter !== '') {
      $jury.each(function() {
        var $juror = $(this);
        var $jurorComponent = $juror.find('.card-component');
        var $filters = $jurorComponent.attr('data-filters').split(',');

        if(!$filters.includes($filter)) {
          $juror.hide();
          $('.jurors-previous .grid--people').append($juror);
        }
      });
    }

    ScrollTrigger.refresh();
    closeDropdown();
  }

  // about section adjustments
  function adjustAboutSections() {
    if($isSM.matches) {
      var $buttons = $('.ss-about-section_button');
      var $buttonWidth = $buttons.first().outerWidth();
      var $width = $window.width - ($buttons.length * $buttonWidth);
      var $openSectionWidth = $window.width - ($buttons.length * $buttonWidth) + $buttonWidth;

      gsap.set('.ss-about-section_content', { width: $width });
      gsap.set('.ss-about-section', { width: $buttonWidth, height: 'auto' });
      gsap.set('.ss-about-section.state--open', { width: $openSectionWidth });
    } else {
      gsap.set('.ss-about-section_content', { width: 'auto' });
      gsap.set('.ss-about-section', { width: 'auto', height: 72 });
      gsap.set('.ss-about-section.state--open', { height: 'auto' });
    }
  }

  // open about section
  function openAboutSection(e) {
    e.preventDefault();

    var $aboutSectionButton = $(this);
    var $aboutSection = $aboutSectionButton.parent();
    var $aboutSectionContent = $aboutSectionButton.next();

    if(!$aboutSection.hasClass('state--open')) {
      var $buttons = $('.ss-about-section_button');
      var $oldAboutSection = $('.ss-about-section.state--open');

      if($isSM.matches) {
        var $buttonWidth = $buttons.first().outerWidth();
        var $width = $window.width - ($buttons.length * $buttonWidth) + $buttonWidth;

        // close previously opened section
        if($oldAboutSection.length) {
          gsap.fromTo($oldAboutSection, {
            width: $width
          }, {
            duration: 1.2,
            ease: 'power4.inOut',
            width: $buttonWidth
          });

          $oldAboutSection.removeClass('state--open');
        }

        // open new section
        gsap.fromTo($aboutSection, {
            width: $buttonWidth
        }, {
          duration: 1.2,
          ease: 'power4.inOut',
          width: $width
        });

        $aboutSection.addClass('state--open');
      } else {
        var $buttonHeight = $buttons.first().outerHeight();
        var $height = $aboutSectionContent.outerHeight() + $buttonHeight;

        // close previously opened section
        if($oldAboutSection.length) {
          gsap.to($oldAboutSection, {
            duration: 1.2,
            ease: 'power4.inOut',
            height: $buttonHeight
          });

          $oldAboutSection.removeClass('state--open');
        }

        // open new section
        $flags.pauseScrolling = true;
        gsap.fromTo($aboutSection, {
            height: $buttonHeight
        }, {
          duration: 1.2,
          ease: 'power4.inOut',
          height: $height,
          onComplete: function() {
            gsap.to(window, {
              duration: 0.4,
              scrollTo: {
                y: $('.ss-about-sections').offset().top,
                autoKill: true
              },
              onComplete: function() {
                $flags.pauseScrolling = false;
              }
            });
          }
        });

        $aboutSection.addClass('state--open');
      }
    }
  }

  // adjust horizontal scrolling wrapper
  function adjustHorizontalScrollingWrapper() {
    if($isHorizontal.matches) {
      gsap.set('.wrap', { height: $('.side-scroller_wrapper')[0].scrollWidth - ($window.width - $window.height) });
    } else {
      gsap.set('.wrap', { height: 'auto' });
    }
  }

  // setup PAS horizontal scrolling
  function setupPASHorizontalScrolling() {
    $mm.add($mqHorizontal, function() {
      ScrollTrigger.create({
        trigger: '.side-scroller_wrapper',
        start: 'top 0%',
        endTrigger: '.wrap',
        end: 'bottom 100%',
        pin: true,
        onUpdate: function() {
          gsap.to('.side-scroller_wrapper', {
            duration: 0,
            scrollTo: {
              x: $smoother.scrollTop(),
              autoKill: true
            }
          });
        }
      });
    });
  }

  // PAS prize categories animation
  function setupPASPrizeCategoriesAnimation() {
    $mm.add($mqHorizontal, function() {
      var $prizeTimeline =  gsap.timeline({
        scrollTrigger: {
          trigger: '.pas-prize-categories_categories',
          horizontal: true,
          start: 'left 100%',
          end: 'right 75%',
          scrub: 1,
          scroller: '.side-scroller_wrapper'
        }
      });

      var $prizeItems = $('.prize-category, .prize-divider');
      $prizeTimeline.fromTo($prizeItems, {
        x: toPX('25vw')
      }, {
        duration: 1,
        stagger: 0.1,
        x: '0%'
      }, 0);
    });

    $mm.add($mqVertical, function() {
      // prize categories
      var $prizeTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.pas-prize-categories_categories',
          start: 'top 100%',
          end: 'bottom 75%',
          scrub: 1
        }
      });

      var $prizeItems = $('.prize-category, .prize-divider');
      $prizeTimeline.fromTo($prizeItems, {
        x: toPX('25vw')
      }, {
        duration: 1,
        stagger: 0.1,
        x: '0%'
      }, 0);
    });
  }

  // PAS prize finalists animation
  function setupPASPrizeFinalistsAnimation() {
    var $finalistsTimeline = false;

    function prizeFinalistsAnimation() {
      var $distance = $('.the-prize-finalists').outerHeight();

      if($isHorizontal.matches) {
        $finalistsTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '.the-prize-finalists',
            horizontal: true,
            start: 'left 200%',
            end: 'right 100%',
            scrub: 1,
            scroller: '.side-scroller_wrapper'
          }
        });

        $('.the-prize-finalists_images div').each(function(index) {
          var $img = $(this);
          var $speed = Math.random();
          var $y = (1 - parseFloat($speed)) * $distance;

          $finalistsTimeline.to($img, {
            ease: 'Linear.easeNone',
            y: -$y
          }, 0);
        });
      } else {
        $finalistsTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '.the-prize-finalists',
            start: 'top 200%',
            end: 'bottom 0%',
            scrub: 1
          }
        });

        $('.the-prize-finalists_images div').each(function(index) {
          var $img = $(this);
          var $speed = Math.random();
          var $y = (1 - parseFloat($speed)) * $distance;

          $finalistsTimeline.to($img, {
            ease: 'Linear.easeNone',
            y: -$y
          }, 0);
        });
      }
    }
    prizeFinalistsAnimation();

    // reset prize finalists timeline
    function resetPrizeFinalistsAnimation() {
      if($finalistsTimeline) {
        $finalistsTimeline.revert();
        $finalistsTimeline.kill();
        prizeFinalistsAnimation();
      }
    }
    requestResizeWidthAnimation(resetPrizeFinalistsAnimation);
  }

  // filter FAQS
  function filterFAQs($filter) {
    var $faqs = $('.faq-list > li');
    $faqs.show();

    if($filter !== '') {
      $faqs.each(function() {
        var $faq = $(this);
        var $faqComponent = $faq.find('.accordion-component');
        var $filters = $faqComponent.attr('data-filters').split(',');

        if(!$filters.includes($filter)) {
          $faq.hide();
        }
      });
    }
  }

  // open faq accordiont
  function openAccordion(e) {
    e.preventDefault();

    var $heading = $(this);
    var $accordion = $heading.parent();
    var $content = $accordion.find('.accordion-component_content');
    var $contentHeight = $accordion.find('.text-component').outerHeight();

    if($accordion.hasClass('state--open')) {
      gsap.fromTo($content, {
        height:$contentHeight,
      }, {
        duration: 0.3,
        height: 0,
      });

      $accordion.removeClass('state--open');
      $heading.attr('aria-expanded', 'false');
    } else {
      gsap.fromTo($content, {
        height: 0,
      }, {
        duration: 0.3,
        height: $contentHeight,
      });

      $accordion.addClass('state--open');
      $heading.attr('aria-expanded', 'true');
    }
  }

  // supporters list animation
  function setupSupportersListAnimation() {
    var $supportersLines = false;
    var $supportersTimeline = false;

    function supportersListAnimation() {
      $supportersLines = new SplitText('.current-supporters_list', {
        type: 'lines',
        linesClass: 'line'
      });

      $supportersTimeline =  gsap.timeline({
        scrollTrigger: {
          trigger: '.current-supporters_list',
          start: 'top 100%',
          end: 'bottom 60%',
          scrub: 1
        }
      });

      $supportersTimeline.fromTo($supportersLines.lines, {
        x: toPX('25vw')
      }, {
        duration: 1,
        stagger: 0.1,
        //ease: 'power4.inOut',
        x: '0%'
      }, 0);
    }
    supportersListAnimation();

    // reset prize finalists timeline
    function resetSupportersListAnimation() {
      if($supportersTimeline) {
        $supportersTimeline.revert();
        $supportersTimeline.kill();

        $supportersLines.revert();

        supportersListAnimation();
      }
    }
    requestResizeWidthAnimation(resetSupportersListAnimation);
  }

  function initCommon() {
    /* page loader */
    $html.removeClass('state--loading');

    /*var $showLoaderContent = false;
    if($('.loader-content').length > 0) {
      if(!sessionStorage.loaded) {
        sessionStorage.loaded = true;
        $showLoaderContent = true;
      } else {
        $('.loader-content').remove();
      }
    } else {
      $html.removeClass('state--loading');
    }

    var $loadedImages = 0;
    $document.imagesLoaded().always(function() {
      if($showLoaderContent) {
        setTimeout(function() {
          $('.loader-content').addClass('is-loaded');
          gsap.set('.loader-content', { height: '200%' });
          gsap.to('.loader-content', {
            duration: 1,
            width: '200%',
            onComplete: function() {
              $html.removeClass('state--loading');
              setTimeout(function() {
                $('.loader-content').remove();
              }, 1000);
            }
          });
        }, 500);
      } else {
        $html.removeClass('state--loading');
      }
    }).progress(function(instance, image) {
      $loadedImages++;
      var $progress = ($loadedImages/instance.images.length);
      $('.loader').get(0).style.setProperty('--progress', $progress);
    });*/

    /* page unload */
    //window.addEventListener('beforeunload', function() {
      //$html.addClass('state--unloading');
    //});

    // menu toggle
    $document.on('click', '.menu-toggle', function(e) {
      e.preventDefault();

      if($html.hasClass('state--menu-open')) {
        closeMenu();
      } else {
        openMenu();
      }
    });

    // sub menu toggle
    $document.on('click', '.menu-item-has-children > a', function(e) {
      e.preventDefault();

      var $menuItem = $(this).parent();

      if(!$menuItem.hasClass('menu-item--open')) {
        var $oldMenuItem = $('.menu-item--open');
        closeSubMenu($oldMenuItem);

        openSubMenu($menuItem);
      }
    });

    // cursor movement
    $document.on('mousemove', moveCursor);
    $document.on('mouseover', '[data-cursor]', function() {
      var $cursor = $(this).attr('data-cursor');

      $('.cursor').hide();
      $($cursor).show();
      $cursorComponent.addClass('state--visible');
    });
    $document.on('mouseout', '[data-cursor]', function() {
      $('.cursor').hide();
      $cursorComponent.removeClass('state--visible');
    });
    $document.on('mouseover', '[data-cursor] button', function() {
      $cursorComponent.addClass('cursor-component--button-hover');
    });
    $document.on('mouseout', '[data-cursor] button', function() {
      $cursorComponent.removeClass('cursor-component--button-hover');
    });
    
    // various animations
    //document.fonts.ready.then(setupTextAnimations);
    setupTextAnimations();
    setupImageComponentAnimations();
    setupRevealButtonAnimations();
    setupSplitScrollSections();
    setupTwoColumnAnimations();
    setupCardAnimations();

    // form inputs
    $('.form-row').each(function() {
      setupFormRow($(this));
    });

    // find contact form 7 forms
    var $wpcf7Elm = document.querySelector('.wpcf7');
    if($wpcf7Elm !== null) {
      // disable submit button on submission
      $wpcf7Elm.addEventListener('submit', function(e) {
        $(this).find('button[type="submit"]').attr('disabled', true).addClass('btn--submitting');
      }, false);

      // re-enable submit button after submission
      $wpcf7Elm.addEventListener('wpcf7submit', function(e) {
        $(this).find('button[type="submit"]').removeAttr('disabled').removeClass('btn--submitting');
      }, false);

      // reset form labels after successful submission
      $wpcf7Elm.addEventListener('wpcf7mailsent', function(e) {
        $(this).find('label:not(.checkbox)').removeClass('shrink');
      }, false);
    }

    // anchor links
    $document.on('click', 'a[href^="#"]', function(e) {
      var $link = $(this);
      var $anchorTarget = $($link.attr('href'));

      if($anchorTarget.length) {
        e.preventDefault();

        var $offsetLeft = $anchorTarget.offset().left;
        var $offsetTop = $anchorTarget.offset().top;

        if($isPAS && $isHorizontal.matches) {
          $smoother.scrollTo($offsetLeft, 1000);
        } else {
          gsap.to(window, {
            duration: 1,
            scrollTo: {
              x: $offsetLeft,
              y: $offsetTop
              //autoKill: true
            }
          });
        }
      }
    });

    // sliders
    $document.on('click', '[data-slider]', function(e) {
      e.preventDefault();

      var $button = $(this);
      var $slider = $button.attr('data-slider');
      var $sliderContent = '';

      if($slider === '#vote') {
        $sliderContent = $button.next().clone();
      } else {
        $sliderContent = $($slider).clone();
      }

      $('.slider-component_content').html($sliderContent.html());
      $('.slider-component .form-row').each(function() {
        setupFormRow($(this));
      });

      $html.addClass('state--slider-open');
      $smoother.paused(true);
    });

    $('.slider-component_close, .slider-component_mask').click(function(e) {
      e.preventDefault();

      $html.removeClass('state--slider-open');
      $smoother.paused(false);
    });

    // dropdowns
    var $dropdownScrollTrigger = ScrollTrigger.create({
      trigger: '.dropdown-component_overlay',
      pin: '.dropdown-component_overlay-content',
      start: 'top top'
    });

    $document.on('click', '.dropdown-component, .dropdown-component_button', function() {
      var $dropdownComponent = $(this);
      if($dropdownComponent.hasClass('.dropdown-component_button')) {
        $dropdownComponent = $dropdownComponent.parent();
      }
      var $dropdownContent = $dropdownComponent.find('.dropdown-component_content').clone();

      if($dropdownComponent.hasClass('dropdown-component--right')) {
        $('.dropdown-component_overlay').addClass('dropdown-component--right');
      } else {
        $('.dropdown-component_overlay').removeClass('dropdown-component--right');
      }

      $('.dropdown-component_overlay').css({ top: $dropdownComponent.offset().top });
      $('.dropdown-component_overlay-content').html($dropdownContent);
      $dropdownScrollTrigger.refresh();

      $dropdownComponent.addClass('active');
      $html.addClass('state--dropdown-open');
    });

    $('.dropdown-component_mask').click(function(e) {
      e.preventDefault();
      closeDropdown();
    });

    $document.on('mouseup', function(e) {
      var $content = $('.dropdown-component_content');
      if(!$content.is(e.target) && $content.has(e.target).length === 0) {
        closeDropdown();
      }
    });

    // exhibition archive
    $document.on('click', '.exhibition-archive_toggle', function(e) {
      $html.addClass('state--exhibition-archive-open');
    });
    $document.on('mouseup', function(e) {
      var $list = $('.exhibition-archive_list');

      if(!$list.is(e.target) && $list.has(e.target).length === 0) {
        $html.removeClass('state--exhibition-archive-open');
      }
    });

    // vote
    $document.on('submit', '.vote-slider_form form', function(e) {
      e.preventDefault();

      var $form = $(this);
      var $formData = $form.serialize();

      var $formBtn = $form.find('.btn');
      $formBtn.addClass('btn--voting');

      // set email cookie for current user
      var $email = $form.find('input[name="email"]').val();
      setCookie('email', $email, 365);
      $('.vote-slider_form input[name="email"]').attr('value', $email);

      $.post($form.attr('action'), $formData, function(response) {
        $formBtn.removeClass('btn--voting');

        if(response) {
          displayVote(response);
          $html.removeClass('state--slider-open');
        } else {
          $('.slider-component .vote-slider_form label').html('An error has occurred. Please try again later.');
          $('.exhibition-vote').hide();
        }
      }, 'json');
    });

    if($('.vote-slider_form form').length > 0) {
      getCurrentVote();
    }

    // random colors
    var $randomColors = [
      [ '--color-purple', '--color-text-dark' ],
      [ '--color-yellow', '--color-text-dark' ],
      [ '--color-red',    '--color-text-light' ],
      [ '--color-green',  '--color-text-light' ],
      [ '--color-orange', '--color-text-dark' ],
      [ '--color-teal',   '--color-text-dark' ],
      [ '--color-blue',   '--color-text-light' ]
    ];
    var $randomColorIndex = 0;

    function updateRandomColor() {
      $randomColorIndex++;
      if($randomColorIndex > ($randomColors.length - 1)) {
        $randomColorIndex = 0;
      }

      gsap.set($html, {
        '--random-bg': 'var('+$randomColors[$randomColorIndex][0]+')',
        '--random-color': 'var('+$randomColors[$randomColorIndex][1]+')'
      });
    }

    $document.on('mouseleave', '.dropdown-component_options li, [data-cursor]', updateRandomColor);
    $document.on('mousedown', updateRandomColor);

    // fixed color for event categories
    $document.on('mouseenter', '.dropdown-component_options a', function() {
      var $option = $(this);
      var $optionHover = $option.attr('data-hover');

      if(typeof $optionHover !== 'undefined' && $optionHover !== false) {
        $option.addClass($optionHover);
      }
    });
    $document.on('mouseleave', '.dropdown-component_options a', function() {
      var $option = $(this);
      $option.removeClass(function(index, className) {
        return (className.match (/(^|\s)bg--\S+/g) || []).join(' ');
      });
    });
  }

  function initHome() {
    setupPageHero();
    //setupHomeExhibitionCalloutAnimation();
    setupHomeTestimonials();
    setupHomePASAnimation();
  }

  function initPrize() {
    setupPageHero();
    setupPrizeCategoriesAnimation();
    setupPrizeFinalistsAnimation();
  }

  function initJury() {
    setupJuryProcessAnimation();

    $document.on('click', '.dropdown-component_options button', filterJurors);
  }

  function initSSNAPSociety() {
    adjustAboutSections();
    requestResizeAnimation(adjustAboutSections);

    $document.on('click', '.ss-about-section_button', openAboutSection);

    $mm.add($mqSM, function() {
      if(!$('.ss-about-section.state--open').length) {
        ScrollTrigger.create({
          trigger: '.ss-about-sections',
          start: 'top 25%',
          onEnter: function() {
            $('.ss-about-section_button').first().trigger('click');
          },
          once: true
        });
      }
    });
  }

  function initPAS() {
    setupPageHero();
    adjustHorizontalScrollingWrapper();
    requestResizeAnimation(adjustHorizontalScrollingWrapper);
    setupPASHorizontalScrolling();
    setupPASPrizeCategoriesAnimation();
    setupPASPrizeFinalistsAnimation();
  }

  function initFAQs() {
    // tab filters
    $document.on('click', '.btn--tab', function(e) {
      e.preventDefault();

      var $tab = $(this);
      var $filter = $tab.attr('data-filter');

      $('.btn--tab.active').removeClass('active');
      $tab.addClass('active');

      filterFAQs($filter);
    });
    $('.btn--tab.active').trigger('click');

    // select filters
    $document.on('change', '.select-component', function(e) {
      e.preventDefault();

      var $option = $('.select-component option:selected');
      var $filter = $option.attr('data-filter');

      filterFAQs($filter);
    });

    // faq accordions
    $document.on('click', '.accordion-component_heading', openAccordion);
  }

  function initSubmissionInfo() {
    setupPageHero();
  }

  function initSupport() {
    //document.fonts.ready.then(setupSupportersListAnimation);
    setupSupportersListAnimation();
  }

  function initSingleArtwork() {
    $document.imagesLoaded().always(setupArtworkGallery);

    $mm.add($mqLG, function() {
      ScrollTrigger.create({
        trigger: '.artwork-content',
        start: 'top 0%',
        end: 'bottom 100%',
        pin: '.artwork-about-artist'
      });
    });
  }

  function initSingleProduct() {
    $document.imagesLoaded().always(setupArtworkGallery);

    if($('.cart .quantity').hasClass('hidden')) {
      $('.qty-wrapper').hide();
    }

    // qty decrease
    $document.on('click', '.qty-decrease', function(e) {
      e.preventDefault();
      
      var $qtyInput = $(this).parent().find('.qty');
      var $qty = parseInt($qtyInput.val());

      if($qty > 1) {
        $qty--;
        $qtyInput.val($qty).trigger('change');
      }
    });

    // qty increase
    $document.on('click', '.qty-increase', function(e) {
      e.preventDefault();

      var $qtyInput = $(this).parent().find('.qty');
      var $qty = parseInt($qtyInput.val());

      $qty++;
      $qtyInput.val($qty).trigger('change');
    });

    // create new div to hold auction details
    $('.uwa_auction_product_ajax_change').prepend($('<div class="auction-details" />'));

    // wrap end time and timezone in div
    var $div = $('<div />');
    $div.append($('.uwa_auction_end_time'));
    $div.append($('.uwa_auction_product_timezone'));
    $('.auction-details').append($div);

    // move current condition into auction details
    $('.auction-details').append($('.uwa_auction_condition'));

    // move auction price into current bid details
    $('.checkreserve').prepend($('.auction-price').html());

    // move current bid details into auction details
    $('.auction-details').append($('.checkreserve'));

    // move watchlist buttons into auction details
    $('.auction-details').append($('.uwa-watchlist-button'));

    // auction bid input tweak
    $('#uwa_bid_value').attr('placeholder', '0');

    // auction bid table tweak
    $('.auction-history-table .start .bid_date').attr('colspan', 1);

    // watch for box office ticket form changes
    if($('.wc-box-office-ticket-form').length > 0) {
      $('.qty-wrapper .wc-box-office-ticket-form').remove();
      var $observer = new MutationObserver(function() {
        $('.qty-wrapper .wc-box-office-ticket-form').remove();
      });
      $observer.observe(document.querySelector('.qty-wrapper'), {
        childList: true
      });
    }

    // ajax add to cart
    $document.on('click', '.single_add_to_cart_button', function(e) {
      if($('.wc-box-office-ticket-form').length > 0) {
        $('.wc-box-office-ticket-fields:not(.active)').each(function() {
          var $fieldGroup = $(this);
          var $toggle = $fieldGroup.find('.wc-box-office-ticket-fields-title a');
          var $fields = $fieldGroup.find('[required]');

          var $flagged = false;
          $fields.each(function() {
            if($(this).val() === '') {
              $flagged = true;
            }
          });

          if($flagged) {
            $toggle.trigger('click');
          }
        });
      } else {
        e.preventDefault();

        var $button = $(this);
        var $form = $button.closest('form.cart');
        var $id = $button.val();
        var $product_qty = $form.find('input[name=quantity]').val() || 1;
        var $product_id = $form.find('input[name=product_id]').val() || $id;
        var $variation_id = $form.find('input[name=variation_id]').val() || 0;

        var $formData = {
          action: 'woocommerce_ajax_add_to_cart',
          product_id: $product_id,
          product_sku: '',
          quantity: $product_qty,
          variation_id: $variation_id,
        };

        $body.trigger('adding_to_cart', [$button, $formData]);

        $button.removeClass('btn--added').addClass('btn--adding');
        $.post(wc_add_to_cart_params.ajax_url, $formData, function(response) {
          if(response.error && response.product_url) {
            window.location = response.product_url;
            return;
          } else {
            /*if(response.cart_count === 0) {
              $('.cart-icon, .cart-link').addClass('cart-empty');
              $('.cart-link span').html('(0)');
            } else {
              $('.cart-icon, .cart-link').removeClass('cart-empty');
              $('.cart-link span').html('('+response.cart_count+')');
            }*/

            $body.trigger('added_to_cart', [response.fragments, response.cart_hash, $button]);

            $button.addClass('btn--added').removeClass('btn--adding');

            setTimeout(function() {
              $button.removeClass('btn--added');
            }, 3000);
          }
        }, 'json');

        /*$.ajax({
          type: 'post',
          url: wc_add_to_cart_params.ajax_url,
          data: $data,
          beforeSend: function(response) {
            $button.removeClass('btn--added').addClass('btn--adding');
          },
          complete: function(response) {
            $button.addClass('btn--added').removeClass('btn--adding');

            setTimeout(function() {
              $button.removeClass('btn--added');
            }, 3000);
          },
          success: function(response) {
            if(response.error && response.product_url) {
              window.location = response.product_url;
              return;
            } else {
              $body.trigger('added_to_cart', [response.fragments, response.cart_hash, $button]);
            }
          }
        });*/

        return false;
      }
    });
  }

  function initMyAccount() {
    $document.on('click', '#customer_login .col-1 h2', function(e) {
      e.preventDefault();

      $('#customer_login .col-1').removeClass('not-active');
      $('#customer_login .col-2').removeClass('active');
    });
    $document.on('click', '#customer_login .col-2 h2', function(e) {
      e.preventDefault();

      $('#customer_login .col-1').addClass('not-active');
      $('#customer_login .col-2').addClass('active');
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: initCommon
    },
    'page_template_home': {
      init: initHome
    },
    'page_template_the_prize': {
      init: initPrize
    },
    'page_template_the_jury': {
      init: initJury
    },
    'page_template_ssnap_society': {
      init: initSSNAPSociety
    },
    'page_template_pas': {
      init: initPAS
    },
    'page_template_faqs': {
      init: initFAQs
    },
    'page_template_submission_info': {
      init: initSubmissionInfo
    },
    'page_template_donate': {
      init: initSupport
    },
    'page_template_volunteer': {
      init: initSupport
    },
    'single_artwork': {
      init: initSingleArtwork
    },
    'single_product': {
      init: initSingleProduct
    },
    'my_account': {
      init: initMyAccount
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
